@font-face {
  font-family: RobotoCondensed;
  src: url("roboto-condensed.light.a65cadfd.ttf");
  font-weight: 800;
}

@font-face {
  font-family: EconomicaBold;
  src: url("economica-bold.dd8d38e2.ttf");
}

body {
  padding: 2.5%;
  font-family: RobotoCondensed;
  font-size: 20px;
  text-align: center !important;
}

h1, h2, h3, h4, h5 {
  font-family: EconomicaBold;
}

.profile {
  border-radius: 100px;
  width: 200px;
  height: 200px;
}

.title-fade-out {
  opacity: 0;
  animation-name: fade-out;
  animation-duration: 2s;
}

.title-fade-in {
  opacity: 100;
  animation-name: fade-in;
  animation-duration: 2s;
}

@keyframes fade-out {
  from {
    opacity: 100;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

.track {
  cursor: pointer;
  width: 250px;
  height: 250px;
  margin: 20px;
  display: inline-block;
  position: relative;
}

.track-image {
  width: 250px;
  height: 250px;
}

.track-text {
  color: #fff;
  background-color: #000;
  width: 250px;
  padding: 5px;
  position: absolute;
  bottom: 0;
}

.track-icon {
  color: #fff;
  opacity: .8;
  background-color: #000;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  padding: 10px;
  position: absolute;
  bottom: 100px;
  left: 100px;
}

@font-face {
  font-family: RobotoCondensed;
  src: url("roboto-condensed.light.a65cadfd.ttf");
  font-weight: 800;
}

@font-face {
  font-family: EconomicaBold;
  src: url("economica-bold.dd8d38e2.ttf");
}

body {
  padding: 2.5%;
  font-family: RobotoCondensed;
  font-size: 20px;
  text-align: center !important;
}

h1, h2, h3, h4, h5 {
  font-family: EconomicaBold;
}

.profile {
  border-radius: 100px;
  width: 200px;
  height: 200px;
}

.title-fade-out {
  opacity: 0;
  animation-name: fade-out;
  animation-duration: 2s;
}

.title-fade-in {
  opacity: 100;
  animation-name: fade-in;
  animation-duration: 2s;
}

@keyframes fade-out {
  from {
    opacity: 100;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

@font-face {
  font-family: RobotoCondensed;
  src: url("roboto-condensed.light.a65cadfd.ttf");
  font-weight: 800;
}

@font-face {
  font-family: EconomicaBold;
  src: url("economica-bold.dd8d38e2.ttf");
}

body {
  padding: 2.5%;
  font-family: RobotoCondensed;
  font-size: 20px;
  text-align: center !important;
}

h1, h2, h3, h4, h5 {
  font-family: EconomicaBold;
}

.profile {
  border-radius: 100px;
  width: 200px;
  height: 200px;
}

.title-fade-out {
  opacity: 0;
  animation-name: fade-out;
  animation-duration: 2s;
}

.title-fade-in {
  opacity: 100;
  animation-name: fade-in;
  animation-duration: 2s;
}

@keyframes fade-out {
  from {
    opacity: 100;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

/*# sourceMappingURL=index.541be73e.css.map */
